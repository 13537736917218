<template>
  <div class="Pagebox">
    <div class="Container">
      <div class="Box"></div>
    <div class="pageHeader">
      <div style="padding-top: 0px"> {{$t('member.title')}}</div>
    </div>
    <div style="overflow:auto;">
    <div class="Panel" style="width: 350px;" v-show="status>=1">
      <div>
        <div class="title">{{$t('member.name')}}</div>
      </div>
      <div style="margin:0 auto;text-align:center">
          <van-field v-model="postData.name"
                     type="text" :placeholder="$t('member_placeholder.name')"
                     autocomplete="off"
          />
      </div>
      <van-button color="#F1A841" style="border-radius: 10px;width: 100%" @click="onSubmit()">{{ $t('member.submit') }}</van-button>
    </div>
    <div class="Panel" style="overflow:auto;width: 350px;padding-top: 0px" v-show="status==2">
      <div style="font-weight: bold">{{$t('member.title2')}}</div>
      <div class="pageContent" style=";text-align: left">
        <div style="margin: 0px auto;text-align: center">
          <van-image :src="'https://yx862.com'+userInfo['header']" width="130px"/>
        </div>
        <div style="display: flex">
          <div style="width: 100px">{{$t('member.status[0]')}}</div>
          <div>{{userInfo['members_name']}}</div>
        </div>
        <div style="display: flex">
          <div style="width: 100px">{{$t('member.status[1]')}}</div>
          <div>{{userInfo['code']}}</div>
        </div>
        <div style="display: flex">
          <div style="width: 100px">{{$t('member.status[2]')}}</div>
          <div>{{userInfo['organ']}}</div>
        </div>
        <!--div style="line-height: 20px;color: #F1A841;margin-top: 10px">{{$t('member.status[3]')}}</div-->
      </div>
      <van-button color="#F1A841" style="margin-top:10px;border-radius: 10px;width: 40%" @click="onSubmit()">
        {{ $t('member.submit2') }}</van-button>
    </div>
    <div class="Panel" style="width: 350px;" v-show="status==3">
      {{$t('member.status2[0]')}}
    </div>
    </div>
    </div>
    <div :class="status==1?'pageFooter pageF2':'pageFooter'">
      <van-image src="./skin/footer_bg.png" width="60" style="text-align: center;margin: 0 auto"/>
      <div style="margin:0 auto;margin-top: 5px;border-bottom: 1px solid #A0BBDC;width: 50%;text-align: center">
        <span style="font-size: 18px;color: #E8F5FC">CAA</span>
        <span style="">.GOV.VN</span> </div>
      <div>CỤC HÀNG KHÔNG VIỆT NAM</div>
      <div  style="font-size: 17px;margin-top: 3px">
        SITE No. VN809103918
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'HelloWorld',
  data () {
    return {
      status:1,
      userInfo:{members_name:'a',code:'b',organ:'c'},
      postData:{name:null,description:null},
    }

  },
  mounted () {

  },
  methods: {
    init(){

    },
    onSubmit() {
      var msg = null;
      var postData = this.postData;
      if (!postData.name||postData.name.length<=0){
        msg = this.$t("member_placeholder.name");
      }
      if (msg){
        this.$Dialog.Toast(msg);
        return;
      }

      this.$Model.findMember(this.postData, (data) => {
        var msg = this.$t("common[1]");
        if (data.code == 0) {
          msg = data.code_dec;
        }
        // this.$Dialog.Toast(msg);
        if (data.code>0){
          this.status = data.status;
          if (data.status==2){
            this.userInfo = data.info;
          }
        }
        if (data.status==1||data.status==2){
          this.status = 2;
        }
      });
    },
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

.Pagebox {
  position: relative;
  min-height: 900px;
}

h1, h2 {
  font-weight: normal;
}
ul {
  list-style-type: none;
  padding: 0;
}

.pageHeader{
  height: 40px;
  margin: 0 auto;
  margin-bottom: 0px;
  color:  #0A56A2;
  font-size: 19px;
  font-weight: bold;
  text-align: center;
}

.pageContent {
  border: 1px solid #F1A841;
  margin: 0px auto;
  margin-top: 10px;
  padding: 20px 40px;
  line-height: 30px
}
.title {
  text-align: left;margin: 10px 0px;font-weight: bold;
  font-size: 17px;
}
 .Panel >>> div {

 }
.van-field {
  width: 350px;
  margin: 0 auto;
  margin: 20px 0px;
  text-align: center;
  border:1px solid #cccccc;
  border-radius: 5px;
}
.Panel{
  text-align: center;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}

.pageFooter {
  background-color: #0A56A2;
  width:100%;
  color:#A0BBDC;
  height: 100px;
  padding: 5px 0px;
  z-index:1;
  text-align: center;
}

.pageF2 {
  position:absolute;
  bottom:0px;
  left:0px;
}
</style>

